import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'
import wmAlert from '../helpers/wmAlerts'

export default defineComponent({
    computed: {
        ...mapGetters({
            hasPlan: 'user/hasPlan',
            isAdmin: 'user/isAdmin',
            isWorksheet: 'document/isWorksheet',
        }),
        ...mapState(['user', 'premium', 'document']),
        showSinglePrice() {
            if (!this.isEditor) return false

            if (!this.document.user_id) return true

            return !this.hasUserPurchasedThisDocument(this.document) && this.document.user_id == this.user.user.id
        },
    },
    methods: {
        editPaywall(document, handler) {
            localStorage.setItem('DocumentZoom', JSON.stringify({ id: this.document.id, zoom: this.document.zoom }))
            this.$store.dispatch('document/getPremiumFeaturesByDocument', document).then((premiumFeatures) => {
                if (!this.isAdmin) {
                    // has single payment
                    const isWorksheet = document.entity_type === 'worksheet'
                    if ((premiumFeatures.length || !isWorksheet) && this.hasUserPurchasedThisDocument(document)) {
                        let hasEdited = document.single_purchase_edits > 0
                        let singlePurchases = []
                        const singlePurchaseEdits = window.localStorage.getItem('single_purchase_edits')
                        if (singlePurchaseEdits) {
                            try {
                                singlePurchases = JSON.parse(singlePurchaseEdits)
                                hasEdited = singlePurchases.includes(document.id) || document.single_purchase_edits > 0
                            } catch (e) {}
                        }
                        if (!hasEdited) {
                            wmAlert
                                .confirm({
                                    title: 'Unpublish & Edit?',
                                    html: `<div class="text-center">Are you sure you want to unpublish and change this document?
                                        <div class="text-danger font-weight-bold">You can edit this document only once.</div>
                                    </div>`,
                                })
                                .then((result) => {
                                    if (result.isConfirmed && handler) {
                                        singlePurchases.push(document.id)
                                        localStorage.setItem('single_purchase_edits', JSON.stringify(singlePurchases))
                                        handler(document)
                                    }
                                })
                        } else {
                            wmAlert
                                .confirm({
                                    title: 'Edit unavailable. Create Copy?',
                                    html: `This document has reached the maximum allowed edits and cannot be edited again. To make changes, create a copy below.`,
                                })
                                .then((result) => {
                                    if (result.isConfirmed) {
                                        window.location.replace(`/worksheet/${document.id}/copy`)
                                    }
                                })
                        }

                        return
                    } else if (!this.hasPlan && (premiumFeatures.length || !isWorksheet)) {
                        wmAlert
                            .confirm({
                                title: 'Edit unavailable. Create Copy?',
                                html: `This was published when you had Premium access and cannot be edited. To make changes, create a copy below.`,
                            })
                            .then((result) => {
                                if (result.isConfirmed) {
                                    window.location.replace(`/worksheet/${document.id}/copy`)
                                }
                            })

                        return
                    }
                }

                wmAlert
                    .info({
                        html: 'Are you sure you want to unpublish this worksheet?',
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            handler(document)
                        }
                    })
            })
        },
        hasUserPurchasedThisDocument(document) {
            return (
                document?.user_has_purchased_document ||
                this.user.user.documents_purchased?.some((doc) => {
                    return doc.id === document.id
                })
            )
        },
        hasFeature(feature, document, docType) {
            if (
                this.user.user.is_admin ||
                ['Gratis', 'Lifetime', 'Premium', 'Free Trial'].some((item) => item === this.user.user.subscription_plan)
            ) {
                return true
            }

            if (feature === 'full-access') {
                if (docType === 'crossword' || docType === 'wordsearch') return false
                return !(
                    (docType == 'worksheet' && this.user.user.subscription_plan !== 'Standard') ||
                    (docType == 'bingo' && !this.user.user.subscription_plan.includes('Bingo')) ||
                    (docType == 'flashcard' && !this.user.user.subscription_plan.includes('Flashcard'))
                )
            }

            if (feature === 'organize-folder') {
                return this.user.user.subscription_plan !== 'Free' && this.user.user.subscription_plan !== 'Basic'
            }

            if (feature === 'private') {
                return (
                    !this.isWorksheet ||
                    this.user.user.subscription_plan !== 'Free' ||
                    this.hasUserPurchasedThisDocument(document)
                )
            }

            if (feature === 'formatting') {
                return (
                    this.hasUserPurchasedThisDocument(document) ||
                    !(
                        (docType == 'worksheet' && this.user.user.subscription_plan !== 'Standard') ||
                        (docType == 'bingo' && !this.user.user.subscription_plan.includes('Bingo')) ||
                        (docType == 'flashcard' && !this.user.user.subscription_plan.includes('Flashcard'))
                    )
                )
            }

            if (feature === 'image') {
                return (
                    this.hasUserPurchasedThisDocument(document) ||
                    !(
                        (docType == 'worksheet' && this.user.user.subscription_plan !== 'Standard') ||
                        (docType == 'bingo' && !this.user.user.subscription_plan.includes('Bingo')) ||
                        (docType == 'flashcard' && !this.user.user.subscription_plan.includes('Flashcard'))
                    )
                )
            }

            if (feature === 'remove-logo') {
                return (
                    this.hasUserPurchasedThisDocument(document) ||
                    (this.user.user.subscription_plan !== 'Free' && this.user.user.subscription_plan !== 'Basic')
                )
            }

            if (feature === 'unpublish-and-edit') {
                let hasEdited = document.single_purchase_edits > 0
                let singlePurchases = []
                const singlePurchaseEdits = window.localStorage.getItem('single_purchase_edits')
                if (singlePurchaseEdits) {
                    try {
                        singlePurchases = JSON.parse(singlePurchaseEdits)
                        hasEdited = singlePurchases.includes(document.id) || document.single_purchase_edits > 0
                    } catch (e) {}
                }
                return !(this.hasUserPurchasedThisDocument(document) && hasEdited)
            }
        },
    },
})
